import { isBefore } from 'date-fns';
import { StudentLoanData } from 'handlers/studentLoanData';

export const getRepaymentLabel = (monthDiff: number, monthsUntilDebtFree: number) => {
  if (monthsUntilDebtFree < 0) return 'Today';
  const monthsUntilEndOfYear = 12 - new Date().getMonth();
  if (monthsUntilDebtFree < monthsUntilEndOfYear) return 'This year';
  if (monthsUntilDebtFree < monthsUntilEndOfYear + 12) return 'Next year';
  if (monthDiff < 12) return `${monthDiff} months earlier`;
  return `${Math.floor(monthDiff / 12)} years earlier`;
};

export const shouldDisplayOffer = ({ paymentStartDate, monthSavings }: StudentLoanData) =>
  paymentStartDate && monthSavings && isBefore(new Date(paymentStartDate), new Date()) && monthSavings > 0;
